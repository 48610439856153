import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"
import HorairesHeader from "../../components/horairesHeader"

const days = {
  monday: "lundi",
  tuesday: "mardi",
  wednesday: "mercredi",
  thursday: "jeudi",
  friday: "vendredi",
  saturday: "samedi",
}

const OuvertureDuBureauPage = ({ data }) => (
  <Layout>
    <Seo title="Ouverture du bureau" breadcrumb={[
      { url: "les-horaires", name: "Les horaires"},
      { url: "ouverture-du-bureau", name: "Ouverture du bureau"},
    ]} />
    <Breadcrumb paths={[
      { url: "/les-horaires/", name: "Les horaires" },
      { url: null, name: "Ouverture du bureau" }
    ]} />

    <HorairesHeader active={3} />

    <table>
    {Object.entries(days).map(day => (
      <tr className="tr">
        <td className="td gradient-dark capitalize">{day[1]}</td>
        <td className="td">{data.strapiHorairesDeBureau[day[0]]}</td>
      </tr>
    ))}
    </table>

  </Layout>
)

export default OuvertureDuBureauPage

export const pageQuery = graphql`
  query OuvertureBureauQuery {
    strapiHorairesDeBureau {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
    }
  }
`
