import * as React from "react"
import { Link } from "gatsby"

const HorairesHeader = ({active}) => (
  <div className="mb-8">
    <h1 className="h1">Les Horaires</h1>
    <div className="flex overflow-x-auto pb-4 -mr-6 md:mr-0">
      <Link
        to="/les-horaires/tests-de-code"
        className={`px-4 py-2 mr-6 rounded-full font-bold whitespace-nowrap	 ${active === 1 ? 'gradient-primary text-dark' : 'gradient-dark'}`}
      >
        Tests de code
      </Link>
      <Link
        to="/les-horaires/cours-theoriques"
        className={`px-4 py-2 mr-6 rounded-full font-bold whitespace-nowrap	 ${active === 2 ? 'gradient-primary text-dark' : 'gradient-dark'}`}
      >
        Cours théoriques
      </Link>
      <Link
        to="/les-horaires/ouverture-du-bureau"
        className={`px-4 py-2 mr-6 rounded-full font-bold whitespace-nowrap	 ${active === 3 ? 'gradient-primary text-dark' : 'gradient-dark'}`}
      >
        Ouverture du bureau
      </Link>
    </div>
  </div>
)

export default HorairesHeader
